import React from 'react';
import * as clients from 'ducks/clients';
import * as employees from 'ducks/employees';
import * as sites from 'ducks/sites';
import * as mobilePatrols from 'ducks/mobile-patrols';
import * as codes from 'ducks/codes';
import * as roles from 'ducks/roles';
import * as forms from 'ducks/forms';
import * as checkpoints from 'ducks/checkpoints';
import * as areas from 'ducks/areas';
import * as tours from 'ducks/tours';
import { CHECKPOINT_STATUSES, REPEAT_TYPES } from 'app/types/constants';
import { createSelector } from 'reselect';
import { getCodes } from 'ducks/codes';
import { RoleCodes } from 'ducks/roles/types';
import CodeTypes from 'ducks/codes/types';
import _ from 'lodash';
import IntlMessages from 'util/IntlMessages';
import { formatFullName } from 'util/formatting';
import { statuses } from 'app/types/defaults';
import { ActiveStarIcon, CheckStatusIcon, InfoIcon, QRIcon, StopIcon } from 'assets/icons';

// Prepare data for options selector in dropdown or autocomplete
export const getClientsOptions = createSelector(
  clients.selectors,
  ({ list }) =>
    list.map(({ clientID, clientName }) => ({
      key: clientID,
      label: clientName,
    })) || []
);

export const getEmployeesOptions = createSelector(
  employees.selectors,
  ({ list }) =>
    list.map(({ employeeID, firstName, lastName }) => ({
      key: employeeID,
      label: formatFullName(firstName, lastName),
    })) || []
);

export const getCountryOptions = createSelector(
  codes.selectors,
  ({ COUNTRIES }) =>
    COUNTRIES?.map(({ id, shortDescription }) => ({
      key: +id,
      label: shortDescription,
    })) || []
);

export const getPositionsOptions = createSelector(
  codes.selectors,
  ({ POSITIONS }) =>
    POSITIONS?.map(({ id, description }) => ({
      key: +id,
      label: description,
    })) || []
);

export const getContactTypesOptions = createSelector(
  (state) => getCodes(state, CodeTypes.CONTACT_TYPES),
  (contactTypes) =>
    (contactTypes || []).map(({ id, description }) => ({
      key: +id,
      label: description,
    }))
);

export const getInfoNoteCategoryOptions = createSelector(
  (state) => getCodes(state, CodeTypes.INFO_NOTE_CATEGORIES),
  (infoNoteCategories) =>
    (infoNoteCategories || []).map(({ id, description }) => ({
      key: id,
      label: description,
    }))
);

export const getTitlesOptions = createSelector(
  codes.selectors,
  ({ TITLES }) =>
    TITLES?.map(({ id, description }) => ({
      key: +id,
      label: description,
    })) || []
);

export const getRolesOptions = (userType) =>
  createSelector(roles.getRoles(userType), (roles) => {
    const roleOrder = [
      RoleCodes.ADMIN,
      RoleCodes.MANAGER,
      RoleCodes.DISPATCH,
      RoleCodes.SUPERVISOR,
      RoleCodes.GUARD,
    ];

    return _.chain(roles || [])
      .map(({ roleID, roleCode, description }) => ({
        key: +roleID,
        label: description,
        code: roleCode,
      }))
      .sortBy(({ code }) => _.indexOf(roleOrder, code))
      .value();
  });

export const getActiveClientsSelect = createSelector(clients.selectors, ({ list }) => {
  return (
    list
      .filter(({ status }) => status === 'A')
      .map(({ clientID, clientName }) => ({
        key: clientID,
        label: clientName,
      })) || []
  );
});

export const getSitesOptions = createSelector(sites.selectors, ({ list }) =>
  list.map(({ siteID, siteName }) => ({
    key: siteID,
    label: siteName,
  }))
);

export const getActiveSitesSelect = createSelector(sites.selectors, ({ list }) => {
  return list
    .filter(({ status }) => status === 'A')
    .map(({ siteID, siteName }) => ({
      key: siteID,
      label: siteName,
    }));
});

export const getGenderOptions = createSelector(employees.selectors, () => [
  { key: 'M', label: <IntlMessages id="male" /> },
  { key: 'F', label: <IntlMessages id="female" /> },
]);

export const getCheckpointStatusOptions = createSelector(employees.selectors, () => [
  { key: 'all', label: <IntlMessages id="all" />, icon: InfoIcon },
  { key: CHECKPOINT_STATUSES.ACTIVE, label: <IntlMessages id="active" />, icon: CheckStatusIcon },
  { key: CHECKPOINT_STATUSES.INACTIVE, label: <IntlMessages id="inactive" />, icon: StopIcon },
  { key: CHECKPOINT_STATUSES.NO_TAG_ASSIGNED, label: <IntlMessages id="no_tag_assigned" />, icon: QRIcon },
  { key: CHECKPOINT_STATUSES.MASTER, label: <IntlMessages id="master" />, icon: ActiveStarIcon },
]);

export const getEmployeesSelect = createSelector(employees.selectors, ({ list }) => {
  return (list || []).map(({ userID, firstName, lastName }) => ({
    key: userID,
    label: formatFullName(firstName, lastName),
  }));
});

export const getActiveEmployeesSelect = createSelector(employees.selectors, (employees = []) => {
  return employees.list
    .filter(({ status }) => status === 'A')
    .map(({ userID, fullName }) => ({
      key: userID,
      label: fullName,
    }));
});

export const getMpSelect = createSelector(mobilePatrols.selectors, ({ list = [] }) => {
  return list
    .filter(({ status }) => status === 'A')
    .map(({ mobilePatrolID, name }) => ({
      key: mobilePatrolID,
      label: name,
    }));
});

export const getEventTypesSelect = createSelector(
  (state) => getCodes(state, CodeTypes.EVENT_TYPES),
  (eventTypes = []) => {
    return eventTypes.map(({ description, code }) => ({
      key: code,
      label: description,
    }));
  }
);

export const getIRsTypesSelect = createSelector(
  (state) => getCodes(state, CodeTypes.INCIDENT_TYPES),
  (eventTypes = []) => {
    return eventTypes.map(({ description, id }) => ({
      key: id,
      label: description,
    }));
  }
);

export const getClientsList = createSelector(clients.selectors, ({ list }) => {
  return list.map((item) => {
    return {
      clientID: item.clientID,
      clientName: item.clientName,
      province: item.province,
      city: item.city,
      accountManagerFullName: item.accountManagerFullName,
      siteCount: item.siteCount,
      employeeCount: item.employeeCount,
      status: item.status,
    };
  });
});

export const getSitesList = createSelector(sites.selectors, ({ list: sites }) => {
  if (sites) {
    if (sites.length > 0) {
      return [...sites]
        .reverse()
        .map(
          ({
            siteID,
            siteName,
            city,
            clientID,
            clientName,
            employeeCount,
            longitude,
            province,
            scanType,
            status,
            activeEmployeeCount,
          }) => ({
            siteID,
            siteName,
            city,
            clientID,
            clientName,
            employeeCount,
            longitude,
            province,
            scanType,
            status,
            activeEmployeeCount,
          })
        );
    } else {
      return [];
    }
  }
});

export const getCitiesList = createSelector(sites.selectors, ({ list: sites }) => {
  if (sites?.length > 0) {
    return _.uniqBy(
      [...sites]
        .reverse()
        .filter(({ city }) => !!city)
        .map(({ city }) => ({
          key: city,
          label: city,
        })),
      'key'
    );
  } else {
    return [];
  }
});

export const getClientCitiesList = createSelector(clients.selectors, ({ list: clients }) => {
  if (clients?.length > 0) {
    return _.uniqBy(
      [...clients]
        .reverse()
        .filter(({ city }) => !!city)
        .map(({ city }) => ({
          key: city,
          label: city,
        })),
      'key'
    );
  } else {
    return [];
  }
});

export const getClientStatesOptions = createSelector(clients.selectors, ({ list: clients }) => {
  if (clients?.length > 0) {
    return _.uniqBy(
      [...clients]
        .reverse()
        .filter(({ province }) => !!province)
        .map(({ province }) => ({
          key: province,
          label: province,
        })),
      'key'
    );
  } else {
    return [];
  }
});

export const getClientStatusesOptions = () => statuses({ withAll: true });

export const getProvinceList = createSelector(sites.selectors, ({ list: sites }) => {
  if (sites?.length > 0) {
    return _.uniqBy(
      [...sites]
        .reverse()
        .filter(({ province }) => !!province)
        .map(({ province }) => ({
          key: province,
          label: province,
        })),
      'key'
    );
  } else {
    return [];
  }
});

export const getCategoriesOptions = createSelector(
  (state) => getCodes(state, CodeTypes.CATEGORIES),
  (categories = []) => {
    return categories.map(({ id, description }) => ({
      key: id,
      label: description,
    }));
  }
);

export const getLabelsOptions = createSelector(
  (state) => getCodes(state, CodeTypes.LABELS),
  (labels = []) => {
    return labels.map(({ id, description }) => ({
      key: id,
      label: description,
    }));
  }
);

export const getEndShiftReasonsOptions = createSelector(
  (state) => getCodes(state, CodeTypes.END_SHIFT_REASONS),
  (reasons = []) => {
    return reasons.map(({ id, description }) => ({
      key: id,
      label: description,
    }));
  }
);

export const getVisitPurposesOptions = createSelector(
  (state) => getCodes(state, CodeTypes.VISIT_PURPOSES),
  (reasons = []) => {
    return reasons.map(({ id, description }) => ({
      key: id,
      label: description,
    }));
  }
);

export const getFormsOptions = createSelector(forms.selectors, ({ list }) => {
  return (list || []).map(({ formID, name }) => ({
    key: formID,
    label: name,
  }));
});

export const getCheckpointsOptions = createSelector(checkpoints.selectors, ({ list }) => {
  return (list || []).map(({ checkpointID, name }) => ({
    key: checkpointID,
    label: name,
  }));
});

export const getAreasOptions = createSelector(areas.selectors, ({ list }) => {
  return (list || []).map(({ areaID, name }) => ({
    key: areaID,
    label: name,
  }));
});

export const getToursPerSite = createSelector(tours.selectors, ({ list }) => {
  return (list || []).map(({ tourID, tourName }) => ({
    key: tourID,
    label: tourName,
  }));
});

export const getRepeatTypeOptions = createSelector(employees.selectors, () => [
  { key: REPEAT_TYPES.NEVER, label: <IntlMessages id="never" /> },
  { key: REPEAT_TYPES.DAY, label: <IntlMessages id="every_day" /> },
  { key: REPEAT_TYPES.WEEK, label: <IntlMessages id="every_week" /> },
  { key: REPEAT_TYPES.MONTH, label: <IntlMessages id="every_month" /> },
  { key: REPEAT_TYPES.YEAR, label: <IntlMessages id="every_year" /> },
]);
