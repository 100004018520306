import { createAction, createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const biToursBySiteAndDateRequest = createRequest(constants.biToursBySiteAndDate);
export const biToursBySiteAndDateSuccess = createSuccess(constants.biToursBySiteAndDate);
export const biToursBySiteAndDateFailure = createFailure(constants.biToursBySiteAndDate);

export const requestBIToursBySiteAndDate = biToursBySiteAndDateRequest;

export const biToursByDateRequest = createRequest(constants.biToursByDate);
export const biToursByDateSuccess = createSuccess(constants.biToursByDate);
export const biToursByDateFailure = createFailure(constants.biToursByDate);

export const requestBIToursByDate = biToursByDateRequest;

export const biToursBySiteRequest = createRequest(constants.biToursBySite);
export const biToursBySiteSuccess = createSuccess(constants.biToursBySite);
export const biToursBySiteFailure = createFailure(constants.biToursBySite);

export const requestBIToursBySite = biToursBySiteRequest;

export const biToursScheduleStatisticsRequest = createRequest(constants.biToursScheduleStatistics);
export const biToursScheduleStatisticsSuccess = createSuccess(constants.biToursScheduleStatistics);
export const biToursScheduleStatisticsFailure = createFailure(constants.biToursScheduleStatistics);

export const requestBIToursScheduleStatistics = biToursScheduleStatisticsRequest;

export const biToursByEmployeeRequest = createRequest(constants.biToursByEmployee);
export const biToursByEmployeeSuccess = createSuccess(constants.biToursByEmployee);
export const biToursByEmployeeFailure = createFailure(constants.biToursByEmployee);

export const requestBIToursByEmployee = biToursByEmployeeRequest;

export const biTourLogRequest = createRequest(constants.biTourLog);
export const biTourLogSuccess = createSuccess(constants.biTourLog);
export const biTourLogFailure = createFailure(constants.biTourLog);

export const requestBITourLog = biTourLogRequest;

export const setLoadingState = createAction(constants.setLoadingState);
