import preparedReducer from './reducer';
import {
  requestBIToursBySiteAndDate,
  requestBIToursByDate,
  requestBIToursBySite,
  requestBIToursByEmployee,
  requestBITourLog,
  requestBIToursScheduleStatistics,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'biTours';

export const selectors = (state) => state[SLICE_NAME];

export const actions = {
  requestBIToursBySiteAndDate,
  requestBIToursByDate,
  requestBIToursBySite,
  requestBIToursScheduleStatistics,
  requestBIToursByEmployee,
  requestBITourLog,
};
