import { handleActions } from 'redux-actions';
import initialState from './states';

import {
  biToursByEmployeeSuccess,
  biToursByDateSuccess,
  biToursBySiteSuccess,
  biToursBySiteAndDateSuccess,
  setLoadingState,
  biToursScheduleStatisticsSuccess,
  biTourLogSuccess,
} from './actions';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [biToursBySiteAndDateSuccess](state, { payload }) {
      return {
        ...state,
        bySiteAndDate: payload,
      };
    },
    [setLoadingState](state, { payload }) {
      return {
        ...state,
        loading: payload,
      };
    },
    [biToursByDateSuccess](state, { payload }) {
      return {
        ...state,
        byDate: payload,
      };
    },
    [biToursBySiteSuccess](state, { payload }) {
      return {
        ...state,
        bySite: payload,
      };
    },
    [biToursScheduleStatisticsSuccess](state, { payload }) {
      return {
        ...state,
        scheduleStatistics: payload,
      };
    },
    [biToursByEmployeeSuccess](state, { payload }) {
      return {
        ...state,
        byEmployee: payload,
      };
    },
    [biTourLogSuccess](state, { payload }) {
      return {
        ...state,
        tourLog: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
