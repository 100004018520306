import { handleActions } from 'redux-actions';

import {
  updateFilterState,
  reset,
  updateTab,
  updateIRFilter,
  updateMonitoringFilter,
  updateWorkHistoryFilter,
  updateTimesheetsFilter,
  updateActivityMapFilter,
  updateClientDashboardFilter,
  updateAdminClientFilter,
  updateAdminSiteFilter,
  updateAdminEmployeeFilter,
  updateMobileUnitsFilter,
  setIsFilterSelected,
  updatePerformanceFilter,
  updateInfoBoardFilter,
  updateSort,
  updateAdminContactFilter,
  updateFormResultsFilter,
  updateTasksFilter,
  updateToursFilter,
  updateSystemLogFilter,
  updateVisitorsFilter,
  updateVisitorLogFilter,
  updateFormsFilter,
  updateBIFilter,
  updateBIToursFilter,
  updateActivityLogFilter,
} from './actions';
import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';
import update from 'immutability-helper';

export default handleActions(
  {
    [updateFilterState](state, { payload }) {
      return update(state, { filterOpened: { $set: payload } });
    },
    [updateSort](state, { payload }) {
      return {
        ...state,
        sort: payload,
      };
    },
    [updateActivityLogFilter](state, { payload }) {
      return {
        ...state,
        activityLogFilters: {
          ...state.activityLogFilters,
          ...payload,
        },
      };
    },
    [updateInfoBoardFilter](state, { payload }) {
      return {
        ...state,
        infoBoardFilters: {
          ...state.infoBoardFilters,
          ...payload,
        },
      };
    },
    [updatePerformanceFilter](state, { payload }) {
      return {
        ...state,
        performanceFilters: {
          ...state.performanceFilters,
          ...payload,
        },
      };
    },
    [updateIRFilter](state, { payload }) {
      return {
        ...state,
        irFilters: {
          ...state.irFilters,
          ...payload,
        },
      };
    },
    [updateMonitoringFilter](state, { payload }) {
      return {
        ...state,
        monitoringFilters: {
          ...state.monitoringFilters,
          ...payload,
        },
      };
    },
    [updateWorkHistoryFilter](state, { payload }) {
      return {
        ...state,
        workHistoryFilters: {
          ...state.workHistoryFilters,
          ...payload,
        },
      };
    },
    [updateTimesheetsFilter](state, { payload }) {
      return {
        ...state,
        timesheetsFilters: {
          ...state.timesheetsFilters,
          ...payload,
        },
      };
    },
    [updateActivityMapFilter](state, { payload }) {
      return {
        ...state,
        activityMapFilters: {
          ...state.activityMapFilters,
          ...payload,
        },
      };
    },
    [updateClientDashboardFilter](state, { payload }) {
      return {
        ...state,
        clientDashboardFilters: {
          ...state.clientDashboardFilters,
          ...payload,
        },
      };
    },

    [updateTab](state, { payload }) {
      return {
        ...state,
        selectedTab: payload,
      };
    },
    [updateAdminClientFilter](state, { payload }) {
      return {
        ...state,
        adminClientFilters: {
          ...state.adminClientFilters,
          ...payload,
        },
      };
    },
    [updateAdminSiteFilter](state, { payload }) {
      return {
        ...state,
        adminSiteFilters: {
          ...state.adminSiteFilters,
          ...payload,
        },
      };
    },
    [updateAdminEmployeeFilter](state, { payload }) {
      return {
        ...state,
        adminEmployeeFilters: {
          ...state.adminEmployeeFilters,
          ...payload,
        },
      };
    },
    [updateAdminContactFilter](state, { payload }) {
      return {
        ...state,
        adminContactFilters: {
          ...state.adminContactFilters,
          ...payload,
        },
      };
    },
    [updateMobileUnitsFilter](state, { payload }) {
      return {
        ...state,
        adminMobileUnitsFilters: {
          ...state.adminMobileUnitsFilters,
          ...payload,
        },
      };
    },
    [updateFormResultsFilter](state, { payload }) {
      return {
        ...state,
        formResultsFilters: {
          ...state.formResultsFilters,
          ...payload,
        },
      };
    },
    [updateFormsFilter](state, { payload }) {
      return {
        ...state,
        formsFilters: {
          ...state.formsFilters,
          ...payload,
        },
      };
    },
    [updateTasksFilter](state, { payload }) {
      return {
        ...state,
        adminTasksFilters: {
          ...state.adminTasksFilters,
          ...payload,
        },
      };
    },
    [updateToursFilter](state, { payload }) {
      return {
        ...state,
        adminToursFilters: {
          ...state.adminToursFilters,
          ...payload,
        },
      };
    },
    [updateVisitorsFilter](state, { payload }) {
      return {
        ...state,
        visitorsFilters: {
          ...state.visitorsFilters,
          ...payload,
        },
      };
    },
    [updateSystemLogFilter](state, { payload }) {
      return {
        ...state,
        systemLogFilters: {
          ...state.systemLogFilters,
          ...payload,
        },
      };
    },
    [updateVisitorLogFilter](state, { payload }) {
      return {
        ...state,
        visitorLogFilters: {
          ...state.visitorLogFilters,
          ...payload,
        },
      };
    },
    [updateBIFilter](state, { payload }) {
      return {
        ...state,
        biFilters: {
          ...state.biFilters,
          ...payload,
          entities: {
            ...state.biFilters.entities,
            ...(payload.entities || {}),
          },
          events: {
            ...state.biFilters.events,
            ...(payload.events || {}),
          },
        },
      };
    },
    [updateBIToursFilter](state, { payload }) {
      return {
        ...state,
        biToursFilters: {
          ...state.biToursFilters,
          ...payload,
        },
      };
    },
    [setIsFilterSelected](state, { payload }) {
      return update(state, { filterSelected: { $set: payload } });
    },
    [reset](state, { payload }) {
      return payload
        ? {
            ...initialState,
            ...payload,
          }
        : initialState;
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
