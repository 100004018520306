// Activity Monitor
export const STATUS = 'locationStatus';
export const TIME = 'eventTime';
export const LOCATION_ICON = 'location1';
export const LOCATION = 'location';
export const EMPLOYEE_ICON = 'employeeIcon';
export const FULL_NAME = 'fullName';
export const ATTACHMENTS = 'attachments';
export const EVENT_DESCRIPTION = 'eventDescription';
export const EVENT_DETAILS = 'eventDetails';

// Activity Log
export const EMPLOYEE_NAME = 'employeeName';

// IR
export const IR_NUMBER = 'irNumber';
export const INCIDENT_TYPE = 'incidentTypeAlias';
export const INCIDENT_TITLE = 'incidentTitle';
export const SITE = 'site';
export const CLIENT = 'client';
export const AUTHOR_NAME = 'authorName';

// Work History
export const SHIFT_ID = 'shiftID';
export const DATE_START = 'dateStarted';
export const DATE_ENDED = 'dateEnded';
export const TIME_WORKED = 'timeWorked';
