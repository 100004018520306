import { all } from 'redux-saga/effects';

import authSagas from 'ducks/auth/sagas';
import dashboardSagas from 'ducks/dashboard/sagas';
import codesSagas from 'ducks/codes/sagas';
import sitesSagas from 'ducks/sites/sagas';
import employeesSagas from 'ducks/employees/sagas';
import contactsSagas from 'ducks/contacts/sagas';
import employeeInfoSagas from 'ducks/employeeInfo/sagas';
import mobilePatrolsSagas from 'ducks/mobile-patrols/sagas';
import checkpointsSagas from 'ducks/checkpoints/sagas';
import notebookNotesSagas from 'ducks/notebookNotes/sagas';
import notebookNoteTagsSagas from 'ducks/notebookNoteTags/sagas';
import tasksSagas from 'ducks/tasks/sagas';
import taskRecordsSagas from 'ducks/taskRecords/sagas';
import tourLogSagas from 'ducks/tourLog/sagas';
import visitsSagas from 'ducks/visits/sagas';
import incidentReportsSaga from 'ducks/incidentReports/sagas';
import clientsSagas from 'ducks/clients/sagas';
import mediaAssetsSaga from 'ducks/mediaAssets/sagas';
import siteInfoSaga from 'ducks/siteInfo/sagas';
import siteInfoTasksSaga from 'ducks/siteInfoTasks/sagas';
import siteInfoCPsSaga from 'ducks/siteInfoCP/sagas';
import siteInfoToursSaga from 'ducks/siteInfoTours/sagas';
import siteInfoAddressSaga from 'ducks/siteInfoAddress/sagas';
import irPerDateSaga from 'ducks/incidentReportsMonth/sagas';
import statsPerDateSaga from 'ducks/statsPerDate/sagas';
import hoursPerDateSaga from 'ducks/hoursPerDate/sagas';
import activitySaga from 'ducks/activity/sagas';
import activityIR from 'ducks/activityIR/sagas';
import activityMapSaga from 'ducks/activity-map/sagas';
import tsByClient from 'ducks/tsByClient/sagas';
import tsByEmployee from 'ducks/tsByEmployee/sagas';
import tsBySite from 'ducks/tsBySite/sagas';
import shiftsSaga from 'ducks/shifts/sagas';
import helpSaga from 'ducks/help/help.sagas';
import shiftInfoSaga from 'ducks/shiftInfo/sagas';
import loggerSaga from 'ducks/watcher/sagas';
import usersSaga from 'ducks/users/sagas';
import noteTypesSaga from 'ducks/noteTypes/sagas';
import accountInfoSaga from 'ducks/account-info/sagas';
import areasSaga from 'ducks/areas/sagas';
import rolesSaga from 'ducks/roles/sagas';
import infoNoteSaga from 'ducks/info-note/sagas';
import performanceSaga from 'ducks/performance/sagas';
import notificationsSaga from 'ducks/notifications/sagas';
import userManualsSaga from 'ducks/user-manuals/sagas';
import formsSaga from 'ducks/forms/sagas';
import formResultsSaga from 'ducks/formResults/sagas';
import siteEmailSettingsSaga from 'ducks/siteEmailSettings/sagas';
import mobilePatrolEmailSettingsSaga from 'ducks/mobilePatrolEmailSettings/sagas';
import clientEmailSettingsSaga from 'ducks/clientEmailSettings/sagas';
import attachmentsSaga from 'ducks/attachments/sagas';
import profilesSaga from 'ducks/profiles/sagas';
import toursSaga from 'ducks/tours/sagas';
import schedulesSaga from 'ducks/schedules/sagas';
import statisticsSaga from 'ducks/statistics/sagas';
import auditLogSaga from 'ducks/auditLog/sagas';
import companiesSaga from 'ducks/companies/sagas';
import visitorsSaga from 'ducks/visitors/sagas';
import visitorLogSaga from 'ducks/visitorLog/sagas';
import taskLogSaga from 'ducks/taskLog/sagas';
import tsByMobilePatrolSaga from 'ducks/tsByMobilePatrol/sagas';
import analyticsSaga from 'ducks/analytics/sagas';
import visitLocationsSaga from 'ducks/visitLocations/sagas';
import biToursSaga from 'ducks/biTours/sagas';
import shiftLogSaga from 'ducks/shiftLog/sagas';

export default function* rootSaga() {
  yield all([
    areasSaga(),
    authSagas(),
    dashboardSagas(),
    codesSagas(),
    sitesSagas(),
    employeesSagas(),
    contactsSagas(),
    infoNoteSaga(),
    employeeInfoSagas(),
    mobilePatrolsSagas(),
    checkpointsSagas(),
    notebookNotesSagas(),
    notebookNoteTagsSagas(),
    notificationsSaga(),
    userManualsSaga(),
    tasksSagas(),
    taskRecordsSagas(),
    tourLogSagas(),
    visitsSagas(),
    incidentReportsSaga(),
    clientsSagas(),
    mediaAssetsSaga(),
    siteInfoSaga(),
    siteInfoTasksSaga(),
    siteInfoCPsSaga(),
    siteInfoToursSaga(),
    siteInfoAddressSaga(),
    irPerDateSaga(),
    statsPerDateSaga(),
    hoursPerDateSaga(),
    activitySaga(),
    activityIR(),
    tsByClient(),
    tsByEmployee(),
    tsBySite(),
    shiftsSaga(),
    helpSaga(),
    shiftInfoSaga(),
    loggerSaga(),
    usersSaga(),
    noteTypesSaga(),
    accountInfoSaga(),
    rolesSaga(),
    performanceSaga(),
    activityMapSaga(),
    formsSaga(),
    formResultsSaga(),
    siteEmailSettingsSaga(),
    mobilePatrolEmailSettingsSaga(),
    clientEmailSettingsSaga(),
    attachmentsSaga(),
    profilesSaga(),
    toursSaga(),
    schedulesSaga(),
    statisticsSaga(),
    auditLogSaga(),
    companiesSaga(),
    visitorsSaga(),
    visitorLogSaga(),
    analyticsSaga(),
    taskLogSaga(),
    tsByMobilePatrolSaga(),
    visitLocationsSaga(),
    biToursSaga(),
    shiftLogSaga(),
  ]);
}
