import { handleActions } from 'redux-actions';
import { shiftLogSuccess } from './actions';
import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [shiftLogSuccess](
      state,
      {
        payload: {
          page,
          excel,
          payload: { records, ...stats },
        },
      }
    ) {
      if (excel) {
        return {
          ...state,
          excel: records,
        };
      }

      return {
        ...state,
        list: !page || page === 1 ? records : state.list.concat(records || []),
        stats: stats,
        hasMore: !!records?.length,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
