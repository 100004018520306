import { DATE_TIME_FORMAT, TIME_FORMAT_SHORT } from 'app/types/constants';
import moment from 'moment';
import { sprintf } from 'sprintf-js';

export function formatLocalDateHour(date, format) {
  return moment(date).format(format);
}

export function formatLocalDateTime(date, format = DATE_TIME_FORMAT) {
  return moment(date).format(format);
}

export function utcDateToLocal(date, format) {
  if (!date) {
    return date;
  }
  return formatLocalDateTime(moment.utc(date).local(), format);
}

export function formatDateTimeUTC(date, format = DATE_TIME_FORMAT) {
  return moment(date).utc().format(format);
}

export function formatTimeUTC(time, format = TIME_FORMAT_SHORT) {
  return moment(time, format).utc().format(format);
}

export function formatTimeLocal(time, format = TIME_FORMAT_SHORT) {
  return moment.utc(time, format).local().format(format);
}

export function formatUTCInLocal(date, format = DATE_TIME_FORMAT) {
  if (!date) {
    return date;
  }
  return moment.utc(date).local().format(format);
}

export function formatDiffArray(dates, dateEnded) {
  let minutes = dates
    .map((date) => moment.duration(dateEnded.diff(date)).asMinutes())
    .reduce((res, minutes) => {
      return res + minutes;
    }, 0);
  const hours = Math.floor(minutes / 60);
  minutes = minutes - hours * 60;

  return sprintf('%02d:%02d', hours, minutes);
}

export function formatSecondsToTime(seconds) {
  const hrs = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0');
  const mins = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const secs = (seconds % 60).toString().padStart(2, '0');

  return `${hrs}:${mins}:${secs}`;
}

export function formatMonthIntervalUTC(date) {
  return [
    moment(date).startOf('month').utc().format(DATE_TIME_FORMAT),
    moment(date).endOf('month').utc().format(DATE_TIME_FORMAT),
  ];
}

export function formatAddressLine(line1, line2, city, county, province, country, postalCode) {
  let result = '';
  if (line1) {
    result += `${line1}, `;
  }
  if (line2) {
    result += `${line2}, `;
  }
  if (city) {
    result += `${city}, `;
  }
  if (county) {
    result += `${county}, `;
  }
  if (province) {
    result += province;
    if (country) {
      result += `, ${country}, `;
    }
  } else if (country) {
    result += `${country}, `;
  }

  if (postalCode) {
    result += `${postalCode}, `;
  }

  result = result.trim();
  if (result.endsWith(',')) {
    result = result.substr(0, result.length - 1);
  }

  return result;
}

const pattern = /(-?\d+)(\d{3})/;
export function formatBigNumber(number) {
  let x = number.toString();
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

export function randomID() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

export function newPendingID() {
  return `-${randomID()}`;
}

export function isPendingID(id) {
  return `${id}`.startsWith('-');
}

const getDefaultFromDateFormatted = () => moment().startOf('month').format(DATE_TIME_FORMAT);

const getToday = () => {
  const end = moment.utc().endOf('day');
  return moment(end).format(DATE_TIME_FORMAT);
};

export const calculateYearsDifference = (date) => {
  return moment(date).isValid() ? moment().diff(moment(date), 'years') : undefined;
};

export const calculateYearMonthDifference = (date) => {
  if (!moment(date).isValid()) {
    return {};
  }

  const diffDuration = moment.duration(moment().diff(date));
  const yearsDiff = diffDuration.years();
  const monthsDiff = diffDuration.months();

  return {
    year: yearsDiff,
    month: monthsDiff,
  };
};

export const formatFullName = (firstName, lastName) => {
  return `${firstName || ''} ${lastName || ''}`;
};

export const formatNumber = (number) => {
  return number?.toLocaleString('en-US');
};

export { getDefaultFromDateFormatted, getToday };
