import {
  ANALYTICS_TOURS_SCHEDULE_STATISTICS,
  ANALYTICS_TOURS_BY_EMPLOYEE,
  ANALYTICS_TOUR_LOG,
  ANALYTICS_TOURS_BY_DATE,
  ANALYTICS_TOURS_BY_SITE_AND_DATE,
  ANALYTICS_TOURS_BY_SITE,
} from 'app/api-routes';
import axios from 'axios';
import { put, all, takeLatest } from 'redux-saga/effects';
import {
  biToursBySiteAndDateFailure,
  biToursBySiteAndDateSuccess,
  biToursBySiteAndDateRequest,
  setLoadingState,
  biToursByDateFailure,
  biToursByDateSuccess,
  biToursByDateRequest,
  biToursBySiteSuccess,
  biToursBySiteFailure,
  biToursBySiteRequest,
  biToursByEmployeeSuccess,
  biToursByEmployeeFailure,
  biToursByEmployeeRequest,
  biTourLogFailure,
  biTourLogSuccess,
  biTourLogRequest,
  biToursScheduleStatisticsSuccess,
  biToursScheduleStatisticsFailure,
  biToursScheduleStatisticsRequest,
} from './actions';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'app/types/constants';

function* biToursBySiteAndDateSaga({ payload: { month, year } }) {
  const startDate = moment(`${year}-${month}`).startOf('month').format(DATE_TIME_FORMAT);
  const endDate = moment(`${year}-${month}`).endOf('month').format(DATE_TIME_FORMAT);

  const params = { startDate, endDate, timeZoneOffset: moment().utcOffset() };

  try {
    yield put(biToursBySiteAndDateSuccess([]));
    yield put(setLoadingState(true));

    const { payload, warnings } = yield axios.get(ANALYTICS_TOURS_BY_SITE_AND_DATE, { params });
    yield put(biToursBySiteAndDateSuccess(payload, { warnings }));
  } catch (err) {
    yield put(biToursBySiteAndDateFailure(err));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* biToursByDateSaga({ payload: { month, year, siteID } }) {
  const startDate = moment(`${year}-${month}`).startOf('month').format(DATE_TIME_FORMAT);
  const endDate = moment(`${year}-${month}`).endOf('month').format(DATE_TIME_FORMAT);

  const params = { startDate, endDate, timeZoneOffset: moment().utcOffset() };

  if (siteID) {
    params.siteID = siteID;
  }

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_TOURS_BY_DATE, { params });
    yield put(biToursByDateSuccess(payload, { warnings }));
  } catch (err) {
    yield put(biToursByDateFailure(err));
  }
}

function* biToursBySiteSaga({ payload: { month, year, siteID } }) {
  const startDate = moment(`${year}-${month}`).startOf('month').format(DATE_TIME_FORMAT);
  const endDate = moment(`${year}-${month}`).endOf('month').format(DATE_TIME_FORMAT);

  const params = { startDate, endDate, timeZoneOffset: moment().utcOffset() };

  if (siteID) {
    params.siteID = siteID;
  }

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_TOURS_BY_SITE, { params });
    yield put(biToursBySiteSuccess(payload, { warnings }));
  } catch (err) {
    yield put(biToursBySiteFailure(err));
  }
}

function* biToursScheduleStatisticsSaga({ payload: { month, year, siteID } }) {
  const startDate = moment(`${year}-${month}`).startOf('month').format(DATE_TIME_FORMAT);
  const endDate = moment(`${year}-${month}`).endOf('month').format(DATE_TIME_FORMAT);

  const params = { startDate, endDate, timeZoneOffset: moment().utcOffset() };

  if (siteID) {
    params.siteID = siteID;
  }

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_TOURS_SCHEDULE_STATISTICS, { params });
    yield put(biToursScheduleStatisticsSuccess(payload, { warnings }));
  } catch (err) {
    yield put(biToursScheduleStatisticsFailure(err));
  }
}

function* biToursGroupByEmployeeSaga({ payload: { month, year, siteID } }) {
  const startDate = moment(`${year}-${month}`).startOf('month').format(DATE_TIME_FORMAT);
  const endDate = moment(`${year}-${month}`).endOf('month').format(DATE_TIME_FORMAT);

  const params = { startDate, endDate, timeZoneOffset: moment().utcOffset() };

  if (siteID) {
    params.siteID = siteID;
  }

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_TOURS_BY_EMPLOYEE, { params });
    yield put(biToursByEmployeeSuccess(payload, { warnings }));
  } catch (err) {
    yield put(biToursByEmployeeFailure(err));
  }
}

function* biTourLogSaga({ payload: { month, year, siteID, tourID, employeeID } }) {
  const startDate = moment(`${year}-${month}`).startOf('month').format(DATE_TIME_FORMAT);
  const endDate = moment(`${year}-${month}`).endOf('month').format(DATE_TIME_FORMAT);

  const params = { startDate, endDate, timeZoneOffset: moment().utcOffset() };

  if (siteID) {
    params.siteID = siteID;
  }

  if (tourID) {
    params.tourID = tourID;
  }

  if (employeeID) {
    params.employeeID = employeeID;
  }

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_TOUR_LOG, { params });
    yield put(biTourLogSuccess(payload, { warnings }));
  } catch (err) {
    yield put(biTourLogFailure(err));
  }
}

export default function* () {
  yield all([
    takeLatest(biToursByDateRequest, biToursByDateSaga),
    takeLatest(biToursBySiteAndDateRequest, biToursBySiteAndDateSaga),
    takeLatest(biToursBySiteRequest, biToursBySiteSaga),
    takeLatest(biToursScheduleStatisticsRequest, biToursScheduleStatisticsSaga),
    takeLatest(biToursByEmployeeRequest, biToursGroupByEmployeeSaga),
    takeLatest(biTourLogRequest, biTourLogSaga),
  ]);
}
