import preparedReducer from './reducer';
import { requestShiftLog } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'shiftLog';

export const selectors = (state) => state[SLICE_NAME];

export const actions = {
  requestShiftLog,
};
